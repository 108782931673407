import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useTheme } from 'styled-components'
import { Container, MultiCartPriceContainer, NotCarts, Title, WrapperContainer } from './styles'

import { useLanguage, useEvent, useConfig, useOrder, useUtils } from '~components'
import { Cart, Button } from '~ui'

const CartGroup = React.memo(({ group, index, renderCart, handleClickCheckout }) => {
  const [, t] = useLanguage()
  const theme = useTheme()
  const [{ parsePrice }] = useUtils()
  const validCarts = group.filter(cart => cart?.valid)
  const hasPending = validCarts.some(cart => cart?.status === 2)
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
        <p
          style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', display: 'flex', alignItems: 'center' }}
        >
          {t('CART', 'Cart')} #{index + 1}
          {hasPending && (
            <p style={{ color: theme?.colors?.primary, fontSize: '12px', marginLeft: '5px' }}>{t('PENDING', 'Pending')}</p>
          )}
        </p>
        {validCarts?.length > 0 && (
          <Button onClick={() => handleClickCheckout(group, validCarts?.length === 1 ? group[0]?.uuid : null)} color='primary'>{t('CHECKOUT', 'Checkout')}</Button>
        )}
      </div>
      {group?.map(cart => renderCart(cart))}
      {validCarts?.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4>{t('TOTAL_FOR', 'Total for')} {t('CART', 'Cart')} #{index + 1}</h4>
          <h4>{parsePrice(validCarts.reduce((total, cart) => total + cart?.total, 0))}</h4>
        </div>
      )}
    </React.Fragment>
  )
})

const CartItem = React.memo(({
  cart,
  isOpenCart,
  isCartPopover,
  isForceOpenCart,
  currentCartUuid,
  onClose,
  setPreorderBusiness,
  configs,
  isCustomerMode,
  productLoading,
  setProductLoading
}) => {
  const [orderState] = useOrder()

  if (!(cart.products.length > 0 || (cart?.reservation && orderState?.options?.type === 9))) {
    return null
  }

  return (
    <Cart
      forceHideCheckoutButton
      cart={cart}
      isCartPending={cart?.status === 2}
      isOpenCart={isOpenCart}
      isCartPopover={isCartPopover}
      isCheckout={window.location.pathname === `/checkout/${cart?.uuid}` && !isCartPopover}
      isForceOpenCart={isForceOpenCart}
      currentCartUuid={currentCartUuid}
      isProducts={cart.products.length}
      onClickCheckout={onClose}
      setPreorderBusiness={setPreorderBusiness}
      businessConfigs={cart?.business?.configs}
      hideCouponInput={configs?.multi_business_checkout_coupon_input_style?.value === 'group'}
      hideDeliveryFee={configs?.multi_business_checkout_show_combined_delivery_fee?.value === '1'}
      hideDriverTip={configs?.multi_business_checkout_show_combined_driver_tip?.value === '1'}
      isCustomerMode={isCustomerMode}
      productLoading={productLoading}
      setProductLoading={setProductLoading}
    />
  )
})

export const CartContent = (props) => {
  const {
    carts,
    isOrderStateCarts,
    isCartPopover,
    isForceOpenCart,
    setPreorderBusiness,
    isOpenCart,
    isSlideBar,
    isCustomerMode
  } = props

  const [, t] = useLanguage()
  const theme = useTheme()
  const [events] = useEvent()
  const [orderState] = useOrder()
  const [{ parsePrice }] = useUtils()
  const [{ configs }] = useConfig()

  const [currentCartUuid, setCurrentCartUuid] = useState(null)
  const [productLoading, setProductLoading] = useState(false)
  const hasCartReservation = carts?.some(cart => cart?.reservation)
  const checkoutMultiBusinessEnabled = orderState?.options?.type !== 9 && !hasCartReservation && configs?.checkout_multi_business_enabled?.value === '1'
  const totalCartsPrice = carts?.length && carts.reduce((total, cart) => { return total + cart?.total }, 0)
  const totalCartsFee = carts?.length && carts
    ?.filter((cart) => cart?.status !== 1 && cart?.valid)
    ?.reduce((total, cart) => { return total + (cart?.delivery_price_with_discount) }, 0)

  const cartsAvailable = useMemo(() => Object.values(orderState?.carts || {})?.filter?.(cart => cart?.valid), [orderState?.carts])

  const cartGroups = useMemo(() => {
    if (!carts?.length) return {}
    return carts.sort((a, b) => b?.status - a?.status).reduce((groups, cart) => {
      const groupId = cart?.status === 2 ? [`pending:${cart?.group?.uuid || cart?.uuid}`] : ['active']
      groups[groupId] = [...(groups[groupId] || []), cart]
      return groups
    }, {})
  }, [carts])

  const renderCart = useCallback((cart) => (
    <CartItem
      key={cart.uuid}
      cart={cart}
      isOpenCart={isOpenCart}
      isCartPopover={isCartPopover}
      isForceOpenCart={isForceOpenCart}
      currentCartUuid={currentCartUuid}
      onClose={props.onClose}
      setPreorderBusiness={setPreorderBusiness}
      configs={configs}
      isCustomerMode={isCustomerMode}
      productLoading={productLoading}
      setProductLoading={setProductLoading}
    />
  ), [currentCartUuid, isOpenCart, isCartPopover, isForceOpenCart, configs, isCustomerMode, productLoading])

  const handleAddProduct = (product, cart) => {
    setCurrentCartUuid(cart?.uuid)
  }

  const handleSetCurrentCartUuid = () => {
    setCurrentCartUuid(null)
    props.onClose && props.onClose()
  }

  const handleClickCheckout = async (group, singleUuid) => {
    const isSingleCart = cartsAvailable.length === 1
    const hasNotGroup = group.some(cart => !cart?.group?.uuid)
    const cartGroupUuid = group[0]?.group?.uuid
    if (isSingleCart || singleUuid) {
      events.emit('go_to_page', { page: 'checkout', params: { cartUuid: singleUuid } })
    } else if (hasNotGroup) {
      events.emit('go_to_page', { page: 'multi_cart' })
    } else {
      events.emit('go_to_page', { page: 'multi_checkout', params: { cartUuid: cartGroupUuid } })
    }
    events.emit('cart_popover_closed')
    props.onClose && props.onClose()
  }

  useEffect(() => {
    events.on('cart_popover_closed', handleSetCurrentCartUuid)
    events.on('cart_product_added', handleAddProduct)
    return () => {
      events.off('cart_popover_closed', handleSetCurrentCartUuid)
      events.off('cart_product_added', handleAddProduct)
    }
  }, [])

  return (
    <>
      <Container>
        <WrapperContainer>
          {!isSlideBar && (
            <Title>{t('YOUR_CART', 'Your cart')}</Title>
          )}
        </WrapperContainer>
        {isOrderStateCarts && carts?.length > 0 && (
          <>
            {Object.keys(cartGroups)?.length > 0 && checkoutMultiBusinessEnabled
              ? (
                  Object.entries(cartGroups).map(([group, _], i) => (
                  <React.Fragment key={group}>
                    <CartGroup
                      group={cartGroups[group]}
                      index={i}
                      renderCart={renderCart}
                      handleClickCheckout={handleClickCheckout}
                    />
                    <div style={{ height: '1px', backgroundColor: '#ccc', width: '100%', position: 'absolute', left: '0px' }} />
                  </React.Fragment>
                  ))
                )
              : (
                  carts?.map(renderCart)
                )}
            {checkoutMultiBusinessEnabled && !!carts.length && (
              <MultiCartPriceContainer>
                {!!totalCartsFee &&
                  configs?.multi_business_checkout_show_combined_delivery_fee?.value === '1' &&
                  (
                    <span>
                      <p>{t('TOTAL_DELIVERY_FEE', 'Total delivery fee')}</p>
                      <p>{parsePrice(totalCartsFee)}</p>
                    </span>
                  )}
                {carts.reduce((sum, cart) => sum + cart?.driver_tip, 0) > 0 &&
                  configs?.multi_business_checkout_show_combined_driver_tip?.value === '1' &&
                  (
                    <span>
                      <p>{t('DRIVER_TIP', 'Driver tip')}</p>
                      <p>{parsePrice(carts.reduce((sum, cart) => sum + cart?.driver_tip, 0))}</p>
                    </span>
                  )}
                <div>
                  <h4>{t('TOTAL_FOR_ALL_CARTS', 'Total for all Carts')}</h4>
                  <h4>{parsePrice(totalCartsPrice)}</h4>
                </div>
                <span>
                  <p>{t('CART_GROUP_MESSAGE_ALERT', 'Discounts may be applied at the time of payment for this group.')}</p>
                </span>
              </MultiCartPriceContainer>
            )}
          </>
        )}
        {(!carts || carts?.length === 0) && (
          <NotCarts>
            <img src={theme.images?.general?.notFound} alt='Not Found' width='200px' height='122px' loading='lazy' />
            <h1>{t('NO_PRODUCTS_TO_CART', 'You have no products added to the cart')}</h1>
          </NotCarts>
        )}
      </Container>
    </>
  )
}
